import React, {useMemo} from "react";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Store from "@material-ui/icons/Store";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";
import {prettyDateUSA, dateToLocal} from "commons-ui/core/utils/date";
import {addParamToQueryString, addFeatureList, calcPrice, renderNameVertical} from "ecommerce-commons-ui/core/utils";
import "./style.styl";


function Image(props) {
    const {path, noImage, imageHeight, isSmall, isVertical, isCover, onClick} = props;
    const height = imageHeight || (isVertical ? "260px" : (isSmall ? "80px" : "150px"));
    const style = {backgroundImage: `url(${path})`, backgroundSize: isCover ? "cover" : "contain", height};
    if (noImage) {
        style.backgroundSize = "100px";
        style.backgroundRepeat = "no-repeat";
        style.opacity = "0.7";
    }
    return (
        <div className={"u-relative u-inlineBlock u-verticalAlignTop " + (isCover || isVertical ? "u-marginBottom4" : "u-marginVertical4")} onClick={onClick}>
            <div className={"image-widget-item u-relative u-sizeFullWidth u-cursorPointer "}
                 style={style}></div>
        </div>
    );
}


export default function Item(props) {
    const {item, isLoading, onAdd, isSmall, loading, itemId, location_widget, category_widget, noWatermark, onItemClick, isImagesCover, imagesHeight, isImagesVertical, featuresFunc, imageFeaturesFunc, mediaPath, noImage, isMobile, location, dispatch} = props;
    const itemIdValue = item[itemId || "ecommerce_store_id"];

    if (isLoading || loading) {
        return (
            <div className="tmallItem">
                <Skeleton className="u-margin10" variant="rect" height={isImagesVertical ? 300 : (isMobile ? 160 : 190)}/>
                <Skeleton className="u-margin10" width={"80%"}/>
                <Skeleton className="u-margin10" width={"60%"}/>
            </div>
        );
    }


    const image = (item.images) ? mediaPath + (item.images || "").split(",")[0] : "";

    const categoryFlatDict = (category_widget || {}).categoryFlatDict || {};

    const categoryList = useMemo(() => {
        const categoryList = Object.keys(item.category_id_dict || {}).map((key) => ((categoryFlatDict[key] || {}).ecommerce_store_category || {}));
        return categoryList;
    }, [Object.keys(categoryFlatDict).length]);



    const {price, price_fixed, discount_fixed, discount_percent} = calcPrice(item);
    //const distance = "≈533mi"

    return (
        <div className={"tmallItem product-gallery-item u-sizeFullHeight " + (isSmall ? "small" : "")}>
            <div className="u-flexColumn u-justifyContentStart u-sizeFullHeight u-relative" link-meta={"/product/" + itemIdValue}>
                <Image noImage={!image}
                       imageHeight={imagesHeight}
                       isVertical={isImagesVertical || item.is_image_vertical === "true"}
                       isCover={true}
                       isSmall={isSmall}
                       path={image || "/static/img/no-image.png"}
                       onClick={() => onItemClick(itemIdValue)}/>
                   <div className="u-flexCenter u-justifyContentCenter u-sizeFullHeight">
                    <div className={" u-paddingHorizontal10 u-textAlignCenter u-textColorDarker " + (isSmall ? "u-fontSize9" : "u-marginTop10 u-marginBottom5 u-fontSize16")} onClick={() => onItemClick(itemIdValue)}>
                        {item.name}
                    </div>

                </div>

                    {
                        item.product_available_from || item.product_available_till ?
                            <div>
                                <div className={"u-fontSize10 u-textAlignCenter u-paddingVertical15 u-textColorRed " + (isMobile ? "u-paddingHorizontal10" : "u-paddingHorizontal15")}>
                                    Available for fulfillment only on these dates
                                    {
                                        item.product_available_from ? (" " + prettyDateUSA(new Date(item.product_available_from)) + "") : ""
                                    }
                                     -
                                    {
                                        item.product_available_till ? (" " + prettyDateUSA(new Date(item.product_available_till)) + "") : ""
                                    }
                                </div>
                            </div>
                            :
                            null
                    }

                <div className={"u-sizeFullWidth u-flexCenter u-relative u-flexCenter u-justifyContentCenter u-paddingBottom10 u-paddingTop0 " + (isMobile ? "u-paddingHorizontal10" : "u-paddingHorizontal15")}>
                    <div className="u-flexCenter u-justifyContentCenter u-marginTop5">
                        <div    className={"u-flexCenter u-fontWeightBold u-textColorDarker " + (isSmall ? "u-fontSize10" : "u-fontSize18")} >
                            $ {discount_percent ? discount_fixed : price_fixed}
                        </div>
                        {
                            discount_percent ?
                                <div className="u-flexCenter">
                                    <div    className={"u-flexCenter u-fontSize9 textColorDarker"}
                                            style={{textDecoration: "line-through"}}>
                                        $ {price_fixed}
                                    </div>
                                    <div className="discount_percent">
                                        {discount_percent}%
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>

                    {
                        onAdd ?
                            <div>
                                <Button className="button-secondary u-fontSize7 u-paddingVertical0 u-paddingHorizontal0 u-minWidth20 u-marginLeft15" size="small" onClick={() => onAdd(item)}>Add</Button>
                            </div>
                            :
                            null
                    }
                </div>
                <div>
                </div>
            </div>
        </div>
    );
}
