import React, {useState} from "react";
import Carousel, {Modal, ModalGateway} from "react-images";
import "./style.styl";


export default function ProductImageWidget(props) {
    const {items} = props;
    const [showIndex, setShowIndex] = useState(0);
    const [viewIndex, setviewIndex] = useState(null);

    if (items.length === 0) {
        return (
            <div></div>
        );
    }

    return (
        <React.Fragment>
            <div className="product-image-widget u-relative u-flexColumn u-alignItemsCenter">
                <div className={"item-show image-widget-item u-relative u-width300 u-height300 u-cursorZoomIn"}
                     onClick={() => setviewIndex(showIndex)}
                     style={{backgroundImage: `url(${items[showIndex]})`, backgroundSize: "contain"}}>
                </div>
                {
                    items.length > 1 ?
                        <div className="u-flexCenter u-flexWrap u-marginTop10 u-marginBottom10">
                            {
                                items.map((item, i) => {
                                    const selectedClass = i === showIndex ? " selected" : "";
                                    return (
                                        <div key={i} onClick={() => setShowIndex(i)}
                                             className={"item-preview image-widget-item u-relative u-borderRadius4 u-marginRight5 u-marginBottom5 u-cursorPointer " + selectedClass}
                                             style={{backgroundImage: `url(${item})`, backgroundSize: "cover"}}>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        :
                        null
                }
            </div>

            <ModalGateway>
                {viewIndex !== null ? (
                    <Modal onClose={() => setviewIndex(null)}>
                        <Carousel views={items.map(v => ({src: v.replace("product_500x500", "product")}))} currentIndex={viewIndex}/>
                    </Modal>
                ) : null}
            </ModalGateway>
        </React.Fragment>
    );
}
