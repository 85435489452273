import React, {useState, useEffect} from "react";
import queryString from "query-string";
import { useRouter } from "../../hooks/useRouter";
import { useStore } from "../../context/state";
import Link from "next/link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {dataSourceV2} from "commons-ui/core/DataSource";


export const DeliverySelectDropdown = (props) => {
    const {open, onClose, modelName, itemId} = props;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const { isMobile, apiPath, ecommerceStoreId, dispatch } = useStore();

  const request = () => {
      dataSourceV2(`${modelName}_list?ecommerce_store_id=${ecommerceStoreId}`, {url: apiPath})
          .then(v=> {
              if (!v.message && v.item_list && v.item_list.length) {
                  const items = v.item_list.filter(v => v.type !== "redirect").filter(v => !v.is_hidden);
                  setItems(items);
              }
              setLoading(false);
          });
  };

  useEffect(() => {
      request();
  }, []);

  return (
      <Menu
          id="simple-menu"
          anchorEl={open}
          keepMounted
          className="u-padding0 u-margin0"
          open={Boolean(open)}
          onClose={onClose}
        >
            <div className="u-height50 u-paddingHorizontal16 u-sizeFullWidth u-paddingVertical10 u-borderBottom1 u-borderColorLighter">
                <span className="u-fontWeightBold u-fontSize16">Select Delivery Method</span>
            </div>
            {
                items.map((item, i) => {
                    let link =  "/delivery/" + (item.slug || item[itemId])
                    if (item.name && item.name.toLowerCase().includes("catering")) {
                        link = "/catering";
                    }
                    
                    return (
                        <Link key={i} href={link}>
                            <a>
                                <MenuItem onClick={onClose}>
                                    {item.name}
                                </MenuItem>
                            </a>
                        </Link>
                    );
                })
            }
        </Menu>

  );
};
