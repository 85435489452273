import React, { useEffect, useState, useMemo } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import "./style.styl";

const prices = [0.1, 1.5, 3.4, 0.3];

const formatPrice = (price) => {
    let priceStr = "$";
    if (price < 0) {
        priceStr = "- " + priceStr;
        price = price * -1;
    } else {
        priceStr = "+ " + priceStr;
    }

    if (price && price !== Math.round(price)) {
        priceStr += price.toFixed(2);
    } else {
        priceStr += price.toFixed(0);
    }
    return priceStr;
};

export default function FeatureSelect(props) {
  const {
    selected,
    product,
    attributeList,
    errorDict,
    featureList,
    onFeatureListChange,
    items,
    label,
  } = props;

  const onSelect = (attribute, value, checked) => {
    let newAttributeList = [...featureList];
    const newAttribute = newAttributeList.filter(
      (v) => v.attribute_id === attribute.attribute_id
    )[0] || { ...attribute, values: undefined };
    const isMultiple = newAttribute.is_multiple;
    let newValues = newAttribute.values || [];
    newValues = newValues.filter(
      (v) => v.attribute_value_id !== value.attribute_value_id
    );
    if (!isMultiple) {
      newValues = [];
    }
    if (checked) {
      newValues.push(value);
    }
    newAttribute.values = newValues;
    newAttributeList = newAttributeList.filter(
      (v) => v.attribute_id !== attribute.attribute_id
    );
    if (newValues.length !== 0) {
      newAttributeList.push(newAttribute);
    }
    onFeatureListChange(newAttributeList);
  };

  const selectedDict = useMemo(() => {
    const selectedDict = {};
    attributeList.forEach((attributeView) => {
      const attribute = (attributeView.attribute_view || {}).attribute || {};
      selectedDict[attribute.attribute_id] = JSON.parse(
        product[attribute.code + "__json"] || "{}"
      );
    });
    return selectedDict;
  }, [product, attributeList]);

  return (
    <React.Fragment>
      {attributeList.map((attributeView, i) => {
        const attribute = (attributeView.attribute_view || {}).attribute || {};
        const attributeSelected =
          featureList.filter(
            (v) => v.attribute_id === attribute.attribute_id
          )[0] || {};
        const valuesSelected = attributeSelected.values || [];
        const isVertical = true || attribute.is_layout_vertical;
        const isRequired = attribute.is_value_required;
        const isPriced = attribute.is_priced;
        const itemSelected =
          (selectedDict[attribute.attribute_id] || {}).attribute_value_dict ||
          {};
        const error = errorDict[attribute.attribute_id] || "";

        const requiredText = attribute.is_value_required ? `Must select at least ${attribute.value_selected_min || 1} option${attribute.value_selected_min > 1 ? "s" : ""}.` : "";
        const multipleText = attribute.is_multiple ? `You can choose up to ${attribute.value_selected_max || (attribute.values || []).length}${attribute.is_value_required ? "" : " options"}.` : "";
        const helperText = requiredText + multipleText;
        if (!attribute.values || attribute.values.length === 0) {
          return null;
        }
        console.log(11, attribute);
        return (
          <div key={i} className="u-marginBottom15">
            <div
              className={
                "product-feature-select-widget u-flex " +
                (isVertical ? " u-flexColumn " : "u-flexCenter")
              }
            >
              <span className="u-fontWeightBold">
                {attribute.name}
                {isRequired ? "" : ""}:
              </span>
              {helperText ? (
                <div className="u-textColorNormal u-fontSize10 u-marginTop5">
                  {helperText}
                </div>
              ) : null}
              {(attribute.values || []).map((item, i) => {
                if (!(item.attribute_value_id in itemSelected)) {
                  return null;
                }
                const valueSelected =
                  valuesSelected.filter(
                    (v) => v.attribute_value_id === item.attribute_value_id
                  )[0] || {};
                const isSelected = Object.keys(valueSelected).length !== 0;
                const price = (itemSelected[item.attribute_value_id] || {})
                  .price;
                const selectedClass = isSelected ? " selected" : "";
                if (isVertical) {
                  return (
                    <ListItem
                      key={i}
                      value={item.value}
                      item={item}
                      className="u-padding0 u-flexCenter u-justifyContentSpaceBetween"
                    >
                      <div className="u-flexCenter">
                        <Checkbox
                          color="primary"
                          size="small"
                          checked={isSelected}
                          onChange={() =>
                            onSelect(
                              attribute,
                              {
                                ...item,
                                ...(itemSelected[item.attribute_value_id] ||
                                  {}),
                              },
                              !isSelected
                            )
                          }
                          className="u-marginLeftNegative8 u-padding5 u-marginRight10"
                        />
                        <ListItemText primary={item.name || item.value} />
                        {isPriced && price ? (
                          <div className={"u-marginLeft10 " + (price < 0 ? "" : "u-textColorGreenDark")}>
                            {formatPrice(price)}
                          </div>
                        ) : null}
                      </div>
                      <div></div>
                    </ListItem>
                  );
                }
                return (
                  <div
                    className={" select-item " + selectedClass}
                    onClick={() => onSelect(attribute, item, !isSelected)}
                  >
                    {item.name || item.value}
                  </div>
                );
              })}
            </div>
            {error ? (
              <div className="u-textColorRed u-fontSize12 u-marginTop5">
                {error}
              </div>
            ) : null}
          </div>
        );
      })}
    </React.Fragment>
  );
}
