import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import Launch from "@material-ui/icons/Launch";
import Tooltip from "@material-ui/core/Tooltip";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import ProductDescription from "./ProductDescription";
import queryString from "query-string";
import Close from "@material-ui/icons/Close";
import {DeliverySelectDropdown} from "../DeliverySelectDropdown";
import "./style.styl";


const getStoreId = (path) => {
    if (!path) {
        return "";
    }
    if (path.endsWith("/")) {
        path = path.slice(0, -1);
    }
    const pathSplit = path.split("/");
    return pathSplit[pathSplit.length - 1];
};


export default function ProductDescriptionWidget(props) {
    const {location, isFullscreen, isMobile, mode, onAddToCart, dispatch} = props;
    const [deliveryOpen, setDeliveryOpen] = useState(null);
    const params = queryString.parse(location.search);
    const productId = props.productId || params.show_product || params.show_product_general;
    const isGeneral = !!params.show_product_general;

    const ecommerceStoreId = params.show_product_ecommerce_store_id || props.ecommerceStoreId || getStoreId(location.pathname) || "";

    const onClose = () => {
        if (props.onClose) {
            props.onClose()
            return
        }
        delete params["show_product"];
        delete params["show_product_general"];
        delete params["show_product_ecommerce_store_id"];
        dispatch(location.pathname + "?"+ queryString.stringify(params));
    };

    const onOpenNewWindowLink = () => {
        delete params["show_product"];
        delete params["show_product_general"];
        const link = "/product/" + productId + "?" + queryString.stringify(params);
        return link;
    };

    if (!productId) {
        return null;
    }

    return (
        <Dialog
            fullScreen={isFullscreen}
            maxWidth="md"
            fullWidth
            className={mode === "simple" ? "" : "tree-widget"}
            aria-labelledby="confirmation-dialog-title"
            open={true}
            onClose={onClose}

            >
                <div>
                    <div className="u-flexCenter u-justifyContentSpaceBetween u-paddingVertical5">
                        <div className="u-flexCenter u-paddingLeft24">

                        </div>

                        <div className="u-flexCenter">
                            {
                                isMobile || true ?
                                    null
                                    :
                                    <Tooltip title="Open in new tab">
                                        <a style={{display: "table-cell"}} href={onOpenNewWindowLink()} target="_blank">
                                            <IconButton className="">
                                                <Launch/>
                                            </IconButton>
                                        </a>
                                    </Tooltip>
                            }
                            <IconButton
                                        onClick={onClose}
                                        className="u-marginRight12">
                                <Close/>
                            </IconButton>
                        </div>
                    </div>
                    <DialogContent>
                        <div className="">
                            <ProductDescription {...props}  isGeneral={isGeneral} onAddToCart={(e) => isGeneral ? setDeliveryOpen(e) : props.onAddToCart(e)} ecommerceStoreId={ecommerceStoreId} onClose={onClose} itemIdValue={productId}/>
                        </div>
                    </DialogContent>

                    <DeliverySelectDropdown open={deliveryOpen}
                                            onClose={() => setDeliveryOpen(null)}
                                            itemId="ecommerce_store_delivery_option_id"
                                            modelName="ecommerce_store_delivery_option"/>
                </div>
        </Dialog>
    );
}
